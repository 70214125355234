import { rgba } from 'polished';
import { css } from 'styled-components';
import theme from '~/styles/theme';
import { PartnerKey } from '~/utils/constants';

export const partnerColors: Record<PartnerKey, string> = {
  [PartnerKey.ABN]: theme.colors.abn.primary,
  [PartnerKey.ASR]: '#ffbd00',
  [PartnerKey.AEGON]: '#0079c1',
  [PartnerKey.BudgetThuis]: '#3aaa35',
  [PartnerKey.Consumentenbond]: '#004DF5',
  [PartnerKey.DAK]: '#bf243a',
  [PartnerKey.EasySwitch]: '#007dff',
  [PartnerKey.EBP]: '#96c120',
  [PartnerKey.Ennatuurlijk]: '#ff5402',
  [PartnerKey.FinancieelFit]: theme.colors.financieelFit.primary,
  [PartnerKey.FinancieelZeker]: '#1b7686',
  [PartnerKey.Finzie]: '#f18913',
  [PartnerKey.Florius]: theme.colors.florius.secondary,
  [PartnerKey.GavoorA]: theme.colors.gaVoorA.primary,
  [PartnerKey.GroeneHart]: '#62c06f',
  [PartnerKey.HpHypotheken]: '#4b4e42',
  [PartnerKey.HuisHypotheek]: '#025fac',
  [PartnerKey.Hypokeur]: '#2298b5',
  [PartnerKey.Hypotheek24]: '#1e7fcc',
  [PartnerKey.HypotheekBerekenen]: '#333333',
  [PartnerKey.Hypotheekshop]: '#f7941d',
  [PartnerKey.HypotheekVisie]: '#f7941d',
  [PartnerKey.ImpactHypotheken]: '#009b9a',
  [PartnerKey.Frits]: '#2495ff',
  [PartnerKey.Lot]: '#E5006D',
  [PartnerKey.Mangotree]: '#e69114',
  [PartnerKey.MUNT]: theme.colors.munt.primary,
  [PartnerKey.NBG]: '#f99635',
  [PartnerKey.NIBC]: '#00a4d6',
  [PartnerKey.Overstappen]: '#14ae79',
  [PartnerKey.Promodomo]: '#7ed995',
  [PartnerKey.Pwc]: '#df301d',
  [PartnerKey.Syntrus]: '#005b84',
  [PartnerKey.Triodos]: theme.colors.triodos.primary,
  [PartnerKey.VanBruggen]: '#008e5a',
  [PartnerKey.VanLanschot]: '#2d8f37',
  [PartnerKey.VanLanschotKempen]: '#2d8f37',
  [PartnerKey.Veldsink]: '#0d4006',
  [PartnerKey.Verbrugge]: '',
  [PartnerKey.Venn]: '#ea650d',
  [PartnerKey.Verder]: 'black',
  [PartnerKey.Viisi]: '#99d6cb',
  [PartnerKey.Vista]: '#f9b000',
};

type MediaSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export const getBgGradient = (
  mediaSize: MediaSize,
  bgImageUrl = 'https://storage.googleapis.com/deb-documents/hero_img.jpg',
  partner?: PartnerKey,
) => {
  const secondaryColor = (partner && partnerColors[partner]) ?? theme.colors.green;
  const gradientPositions: Record<MediaSize, string> = {
    xs: '10%',
    sm: '15%',
    md: '20%',
    lg: '25%',
    xl: '33%',
  };
  return css`
    background-image: linear-gradient(
        19deg,
        ${rgba(theme.colors.green, 1)},
        ${rgba(theme.colors.green, 0.5)} ${gradientPositions[mediaSize]},
        ${rgba(theme.colors.green, 0.1)} ${gradientPositions[mediaSize]}
      ),
      linear-gradient(
        161deg,
        ${rgba(secondaryColor, 0.15)},
        ${rgba(secondaryColor, 0.25)} calc(100% - ${gradientPositions[mediaSize]}),
        ${rgba(secondaryColor, 0.5)} calc(100% - ${gradientPositions[mediaSize]})
      ),
      linear-gradient(to bottom, rgba(255, 255, 255, 0.5), ${rgba(theme.colors.green, 0.5)}),
      url(${bgImageUrl});
  `;
};
