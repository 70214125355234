import { Box, Button, Card, Container, Flex, Icon } from '@energiebespaarders/symbols';
import { Play } from '@energiebespaarders/symbols/icons/solid';
import { mediaMin } from '@energiebespaarders/symbols/styles/breakpoints';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useStartFunnel } from '~/hooks/useFunnel';
import { DutyPackageName } from '~/types/graphql-global-types';
import { PartnerKey } from '~/utils/constants';
import AddressInitForm from '../RequestPackage/AddressInitForm';
import { getBgGradient } from './utils';
import type { DefaultTheme } from 'styled-components';

interface StyledHeroProps {
  bgImageUrl?: string;
  partner?: PartnerKey;
  theme: DefaultTheme;
}

export const StyledHero = styled.section<StyledHeroProps>`
  position: relative;
  align-content: center;
  color: white;
  background-repeat: no-repeat;
  background-size: 100%, 100%, 100%, cover;
  background-position: center;
  ${x => getBgGradient('xs', x.bgImageUrl, x.partner)};

  ${mediaMin.sm} {
    ${(x: StyledHeroProps) => getBgGradient('sm', x.bgImageUrl, x.partner)};
  }

  ${mediaMin.md} {
    ${(x: StyledHeroProps) => getBgGradient('md', x.bgImageUrl, x.partner)};
  }

  ${mediaMin.lg} {
    ${(x: StyledHeroProps) => getBgGradient('lg', x.bgImageUrl, x.partner)};
  }

  ${mediaMin.xl} {
    ${(x: StyledHeroProps) => getBgGradient('xl', x.bgImageUrl, x.partner)};
  }

  & h1 {
    font-size: ${x => x.theme.type.scale[2]};
  }
`;

export const StyledHeroLink = styled.div`
  display: inline-block;
  cursor: pointer;
  font-weight: 500;
  transition: color 0.2s;
  color: white;

  &:hover {
    text-decoration: underline;
  }
`;

interface HeroProps {
  useEbvCheck?: boolean;
  partner?: PartnerKey;
  title?: string;
  openVideoModal?: () => void;
  bgImageUrl?: string;
}

const Hero: React.FC<HeroProps> = ({ partner, title, openVideoModal, bgImageUrl }) => {
  const [addressFromParams, setAddressFromParams] = useState<{
    zip: string;
    number: number;
    suffix?: string;
  }>({ zip: '', number: 0 });

  const [selectedHouseId, setSelectedHouseId] = useState('');

  const router = useRouter();
  const params = router.query;

  useEffect(() => {
    if (params?.postcode && params?.huisnummer && !addressFromParams) {
      const { postcode, huisnummer, toevoeging } = params as {
        postcode: string;
        huisnummer: string;
        toevoeging: string;
      };
      setAddressFromParams({
        zip: postcode.toUpperCase(),
        number: parseInt(huisnummer, 10),
        suffix: toevoeging,
      });
    }
  }, [addressFromParams, params]);

  const { startFunnel, loading } = useStartFunnel({ dutyPackage: DutyPackageName.complete });

  return (
    <StyledHero partner={partner} bgImageUrl={bgImageUrl}>
      <Container color="white" py={5}>
        <Flex flexWrap="wrap" mx={[0, 0, -5]} px={4} py={10} alignItems="center">
          <Box width={[1, 1, 1 / 2]} px={[2, 2, 5]}>
            <h1 style={{ lineHeight: 1.2, fontSize: '2.5em' }}>
              {title || (
                <>
                  Het geheim van besparen...
                  <br /> is gewoon beginnen.
                </>
              )}
            </h1>

            {partner !== PartnerKey.GavoorA && openVideoModal && (
              <StyledHeroLink onClick={openVideoModal}>
                <Icon icon={Play} solid fill="white" mr={1} />
                Bekijk hoe eenvoudig ons proces is
              </StyledHeroLink>
            )}
          </Box>
          <Box width={[1, 1, 1 / 2]} px={[2, 2, 5]}>
            <Card bgColor="rgba(255, 255, 255, 0.35)">
              <AddressInitForm
                isActive
                initialValues={addressFromParams}
                selectedHouseId={selectedHouseId}
                setSelectedHouseId={setSelectedHouseId}
                buttonLabel="Gewoon beginnen"
                buttonIcon={null}
                buttonColor="grayBlack"
                buttonBgColor="yellow"
                linkColor="grayDarker"
                onHouseInitialized={startFunnel}
              />
              {selectedHouseId && (
                <Button
                  onClick={() => startFunnel(selectedHouseId)}
                  bgColor="yellow"
                  color="grayBlack"
                  label="Gewoon beginnen →"
                  fluid
                  loading={loading}
                />
              )}
            </Card>
          </Box>
        </Flex>
      </Container>
    </StyledHero>
  );
};

export default Hero;
