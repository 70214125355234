import _ from 'lodash';
const AEGON = '/img/partners/AEGON.svg';
const ASN = '/img/partners/ASN.svg';
const AbnAmro = '/img/partners/AbnAmro.svg';
const Adaxio = '/img/partners/Adaxio.svg';
const Allianz = '/img/partners/Allianz.svg';
const Argenta = '/img/partners/Argenta.svg';
const Attens = '/img/partners/Attens.png';
const BLG = '/img/partners/BLG.svg';
const BudgetThuis = '/img/partners/BudgetThuis.svg';
const CMIS = '/img/partners/CMIS.png';
const CentraalBeheer = '/img/partners/CentraalBeheer.svg';
const Colibri = '/img/partners/Colibri.svg';
const Consumentenbond = '/img/partners/Consumentenbond.svg';
const DAK = '/img/partners/DAK.svg';
const Hypotheekadviseur = '/img/partners/DeHypotheekadviseur.svg';
const DeHypotheker = '/img/partners/DeHypothekerAlt.svg';
const DeNederlandse = '/img/partners/DeNederlandse.svg';
const Dynamic = '/img/partners/Dynamic.png';
const EBP = '/img/partners/EBP.svg';
const EasySwitchKizi = '/img/partners/EasySwitchKizi.svg';
const Ennatuurlijk = '/img/partners/Ennatuurlijk.svg';
const FDC = '/img/partners/FDC.svg';
const FinancieelFit = '/img/partners/FinancieelFit.svg';
const FinancieelZeker = '/img/partners/FinancieelZeker.png';
const Finzie = '/img/partners/Finzie.svg';
const Florius = '/img/partners/Florius.svg';
const Frits = '/img/partners/Frits.svg';
const GavoorA = '/img/partners/GaVoorA.svg';
const GroeneHart = '/img/partners/GroeneHartCustom.svg';
const HPHypotheken = '/img/partners/HPHypotheken.svg';
const Handelsbanken = '/img/partners/Handelsbanken.svg';
const HollandWoont = '/img/partners/HollandWoont.svg';
const HomeInvest = '/img/partners/HomeInvest.svg';
const HuisHypotheek = '/img/partners/HuisHypotheek.svg';
const Huismerk = '/img/partners/Huismerk.svg';
const Hypokeur = '/img/partners/Hypokeur.svg';
const Hypotheek24 = '/img/partners/Hypotheek24.png';
const HypotheekBerekenen = '/img/partners/HypotheekBerekenen.svg';
const HypotheekVisie = '/img/partners/HypotheekVisie.svg';
const Hypotheekshop = '/img/partners/Hypotheekshop.svg';
const Hypotrust = '/img/partners/Hypotrust.png';
const ING = '/img/partners/ING.svg';
const IQWOON = '/img/partners/IQWOON.png';
const ImpactHypotheken = '/img/partners/ImpactHypotheken.svg';
const JIJENWIJ = '/img/partners/JIJENWIJ.svg';
const Lloyds = '/img/partners/Lloyds.svg';
const Lot = '/img/partners/Lot.svg';
const MUNT = '/img/partners/MUNT.svg';
const Merius = '/img/partners/Merius.svg';
const Moneyou = '/img/partners/Moneyou.svg';
const NBG = '/img/partners/NBG.svg';
const NIBC = '/img/partners/NIBC.svg';
const Obvion = '/img/partners/Obvion.png';
const OfferteAdviseur = '/img/partners/OfferteAdviseur.png';
const Onderlinge = '/img/partners/Onderlinge.png';
const OverstappenKizi = '/img/partners/OverstappenKizi.svg';
const Philips = '/img/partners/Philips.png';
const Promodomo = '/img/partners/Promodomo.svg';
const PwC = '/img/partners/PwC.svg';
const RISK = '/img/partners/RISK.svg';
const Rabobank = '/img/partners/Rabobank.svg';
const RegioBank = '/img/partners/RegioBank.svg';
const Robuust = '/img/partners/Robuust.svg';
const SNS = '/img/partners/SNS.svg';
const Slimster = '/img/partners/Slimster.png';
const Solvari = '/img/partners/Solvari.svg';
const Syntrus = '/img/partners/Syntrus.svg';
const Tellius = '/img/partners/Tellius.png';
const Triodos = '/img/partners/Triodos.svg';
const Tulp = '/img/partners/Tulp.png';
const VEH = '/img/partners/VEH.svg';
const VanBruggen = '/img/partners/VanBruggen.svg';
const VanLanschot = '/img/partners/VanLanschot.svg';
const VanLanschotKempen = '/img/partners/VanLanschotKempen.svg';
const Veldsink = '/img/partners/Veldsink.svg';
const Venn = '/img/partners/Venn.svg';
const Verbrugge = '/img/partners/Verbrugge.svg';
const Viisi = '/img/partners/Viisi.svg';
const Vista = '/img/partners/Vista.png';
const Volksbank = '/img/partners/Volksbank.svg';
const Welke = '/img/partners/Welke.svg';
const WoonNu = '/img/partners/WoonNu.png';
const Woonfonds = '/img/partners/Woonfonds.png';
const ASR = '/img/partners/asr.svg';
const bijBouwe = '/img/partners/bijBouwe.svg';
const NN = '/img/partners/nn.svg';
const OfferteNL = '/img/partners/offerteNL.svg';

const partnerLogos: { [x: string]: string } = {
  AbnAmro,
  Adaxio,
  AEGON,
  Allianz,
  Argenta,
  ASN,
  ASR,
  Attens,
  bijBouwe,
  BLG,
  BudgetThuis,
  CentraalBeheer,
  CMIS,
  Colibri,
  Consumentenbond,
  DAK,
  DeHypotheker,
  DeNederlandse,
  Dynamic,
  EasySwitchKizi,
  EBP,
  Ennatuurlijk,
  FDC,
  FinancieelFit,
  FinancieelZeker,
  Finzie,
  Florius,
  Frits,
  GavoorA,
  GroeneHart,
  Handelsbanken,
  HollandWoont,
  HomeInvest,
  HPHypotheken,
  HuisHypotheek,
  Huismerk,
  Hypokeur,
  Hypotheek24,
  Hypotheekadviseur,
  HypotheekBerekenen,
  Hypotheekshop,
  HypotheekVisie,
  Hypotrust,
  ImpactHypotheken,
  ING,
  IQWOON,
  JIJENWIJ,
  Lloyds,
  Lot,
  Merius,
  Moneyou,
  MUNT,
  NBG,
  NIBC,
  NN,
  Obvion,
  OfferteAdviseur,
  OfferteNL,
  Onderlinge,
  OverstappenKizi,
  Philips,
  Promodomo,
  PwC,
  Rabobank,
  RegioBank,
  RISK,
  Robuust,
  Slimster,
  SNS,
  Solvari,
  Syntrus,
  Tellius,
  Triodos,
  Tulp,
  VanBruggen,
  VanLanschot,
  VanLanschotKempen,
  VEH,
  Veldsink,
  Venn,
  Verbrugge,
  Viisi,
  Vista,
  Volksbank,
  Welke,
  Woonfonds,
  WoonNu,
};

/** This uses the actual name of the company as the key, so we can find it by looking at partnerCompany.name */
const partnerCompanyLogos: { [x: string]: string } = {
  '100% Hypotheken': HPHypotheken,
  'a.s.r.': ASR,
  'ABN AMRO': AbnAmro,
  'Groene Hart Hypotheken': GroeneHart,
  'De Hypotheekshop': Hypotheekshop,
  'De Nederlandse': DeNederlandse,
  'Financieel Fit': FinancieelFit,
  'Financieel Zeker': FinancieelZeker,
  'Huis & Hypotheek': HuisHypotheek,
  'Hypotheek Visie': HypotheekVisie,
  'Impact Hypotheken': ImpactHypotheken,
  'MUNT Hypotheken': MUNT,
  'NIBC Direct': NIBC,
  'Offerte.nl': OfferteNL,
  'Triodos Bank': Triodos,
  'Van Lanschot': VanLanschot,
  'Van Lanschot Kempen': VanLanschotKempen,
  'Vereniging Eigen Huis': VEH,
  'Venn Hypotheken': Venn,
  'Vista Hypotheken': Vista,
  'Viisi Hypotheken': Viisi,
};

export function getPartnerLogo(name?: string | null): string {
  if (!name) return '';

  // Check if the name is a key in the partnerLogos object
  const partnerKey =
    _.findKey(
      partnerLogos,
      (_, key) => key === name || key.toLowerCase() === name.toLowerCase().replace(/ /g, ''),
    ) || name;
  const partnerCompany =
    _.findKey(
      partnerCompanyLogos,
      (_, key) => key === name || key.toLowerCase() === name.toLowerCase().replace(/ /g, ''),
    ) || name;
  return partnerLogos[partnerKey] || partnerCompanyLogos[partnerCompany];
}

export default partnerLogos;
